import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/pro-light-svg-icons/faLeaf';
import { AdoptechCheckbox } from '../../../../components/AdoptechCheckbox/AdoptechCheckbox';
import {
  EditSectionProps,
  FormDocumentVisibility,
  TrusthubFormDocument,
} from '../useEditTrustHubSection';
import './PoliciesEditSection.scss';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { TrusthubDocumentExtended } from '../../../../swagger/trusthub/models/TrusthubDocumentExtended';
import { TrusthubSectionModel } from '../../../../swagger/trusthub';
import { DocumentsEditSection } from '../DocumentsEditSection/DocumentsEditSection';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import {
  faLock,
  faLockKeyholeOpen,
  faUserGroup,
} from '@fortawesome/pro-light-svg-icons';
import AdoptechOverflowLine from '../../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import classNames from 'classnames';

import logoIso27001 from 'images/certificates/iso27001.png';
import soc2 from 'images/certificates/soc2.png';
import cyber from 'images/certificates/cyberessentials_certification_mark_colour_small.png';
import cyberEssentialsPlusLogo from 'images/certificates/cyberessentials_certification_mark_plus_colour_small.png';
import icoRegistrationCertificate from 'images/certificates/ico_registration_certificate.png';
import iso9001 from 'images/certificates/iso_9001.png';
import iso20252 from 'images/certificates/iso_20252.png';
import { bulkActionOptions } from '../../useTrustHubSection';

export const getDocVisibilityIcon = (visibility: FormDocumentVisibility) =>
  bulkActionOptions.find(option => option.value === visibility)?.icon ||
  faEyeSlash;

export interface CertificatesEditSectionProps extends EditSectionProps {}

export const certificatesLogos = {
  ['ISO 27001 Certificate']: logoIso27001,
  ['SOC 2 Type II Report']: soc2,
  ['Cyber Essentials Certificate']: cyber,
  ['Cyber Essentials Plus Certificate']: cyberEssentialsPlusLogo,
  ['ICO Registration Certificate']: icoRegistrationCertificate,
  ['ISO 9001']: iso9001,
  ['ISO 20252']: iso20252,
};

type certificatesTypes = keyof typeof certificatesLogos;

export const EditSectionRow: React.FC<{
  doc: TrusthubDocumentExtended;
  formDocument: TrusthubFormDocument;
  handleCheckboxChange: (
    checked: boolean,
    doc: TrusthubDocumentExtended
  ) => void;
  baseCss: string;
  section: TrusthubSectionModel;
}> = ({ doc, formDocument, handleCheckboxChange, baseCss, section }) => {
  if (!formDocument) return null;
  const docVisibilityIcon = getDocVisibilityIcon(formDocument.visibility);

  const isEsg = section.identifier === 'environmental_social_and_governance';
  const isCompliance = section.identifier === 'compliance';
  const isCertificates = section.identifier === 'certificates';

  // if bottom small sections
  if (section.identifier === 'policies' || section.identifier === 'reports') {
    return (
      <div
        className={baseCss + '--document' + ' hoverable'}
        onClick={e => {
          e.preventDefault();
          handleCheckboxChange(!formDocument.checked, doc);
        }}
      >
        <div className={baseCss + '--documentCheckboxSection'}>
          <AdoptechCheckbox
            id={doc.documentId}
            checked={formDocument.checked}
            onChange={e => {
              e.stopPropagation();
              handleCheckboxChange(e.currentTarget.checked, doc);
            }}
            label={<div className="emptyLabel">-</div>}
          />
          <div className={baseCss + '--documentTitle'}>
            <AdoptechOverflowLine>{doc.name}</AdoptechOverflowLine>
          </div>
          <FontAwesomeIcon icon={docVisibilityIcon} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={baseCss + '--document' + ' hoverable'}
      onClick={e => {
        e.preventDefault();
        handleCheckboxChange(!formDocument.checked, doc);
      }}
    >
      <AdoptechCheckbox
        id={doc.documentId}
        checked={formDocument.checked}
        onChange={e => {
          e.stopPropagation();
          handleCheckboxChange(e.currentTarget.checked, doc);
        }}
        label={<div className="emptyLabel">-</div>}
      />
      <div className={baseCss + '--documentLogoWithTitle'}>
        {(isEsg || isCompliance) && (
          <FontAwesomeIcon icon={isCompliance ? faShieldCheck : faLeaf} />
        )}
        {isCertificates && (
          <img
            src={certificatesLogos[doc.name as certificatesTypes]}
            className={baseCss + '--documentLogo'}
          />
        )}

        <div
          className={classNames(baseCss + '--documentTitle', {
            overflowTitleLarge: isEsg || isCompliance,
            overflowTitle: isCertificates,
          })}
        >
          <AdoptechOverflowLine>{doc.name}</AdoptechOverflowLine>
        </div>
        <FontAwesomeIcon icon={docVisibilityIcon} />
      </div>
    </div>
  );
};

export const PoliciesEditSection: React.FC<EditSectionProps> = ({
  baseCss,
  ...props
}) => {
  return <DocumentsEditSection {...props} baseCss={baseCss} />;
};
