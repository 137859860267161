import React, { useEffect } from 'react';
import { CompanyDetailsSection } from '../../CompanyDetailsSection/CompanyDetailsSection';
import { InformationSecuritySection } from '../../InformationSecuritySection/InformationSecuritySection';
import {
  fetchTrustHubAdminVendorUserDetails,
  fetchTrustHubSections,
} from '../../store/trusthubThunks';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { useDispatch, useSelector } from 'react-redux';
import {
  TrusthubSettingsModel,
  TrusthubVendorUserModel,
} from '../../../../swagger/trusthub';
import { TrustHubSettingsDrawer } from '../TrustHubSettingsDrawer/TrustHubSettingsDrawer';
import { ApplicationState } from '../../../../types/applicationState';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import './TrustHubAdminPage.scss';
import { setShowSettingsDrawer } from '../../store/trusthubSlice';
import { CertificationsSection } from '../../CertificationsSection/CertificationsSection';
import { PoliciesSection } from '../../PoliciesSection/PoliciesSection';
import { ControlsSection } from '../../ControlsSection/ControlsSection';

export const TrustHubAdminPage: React.FC = () => {
  const {
    settings,
    sections,
    showSettingsDrawer,
    fetchingSectionsStatus,
    fetchingSettingsStatus,
    fetchingTrusthubAdminVendorUserDetails,
  } = useSelector((state: ApplicationState) => state.trustHub);
  const dispatch = useDispatch();
  const vendor = useSelector(selectCurrentVendor);

  useEffect(() => {
    const onSuccessSettings = (settingsModel: TrusthubSettingsModel) => {
      if (!settingsModel.domain || !settingsModel.brandingColor)
        dispatch(setShowSettingsDrawer(true));
    };
    settings.id && onSuccessSettings(settings);
  }, [settings.id]);
  useEffect(() => {
    const onSuccess = (model: TrusthubVendorUserModel) => {
      dispatch(
        fetchTrustHubSections(
          {
            vendorIdOrDomain: vendor.id,
            includeInvisible: true,
            trusthubVendorUserId: model.id,
          },
          () => {}
        )
      );
    };
    dispatch(fetchTrustHubAdminVendorUserDetails(vendor.id, onSuccess));
  }, [vendor.id]);

  if (
    fetchingSectionsStatus === 'loading' ||
    fetchingSettingsStatus === 'loading' ||
    fetchingTrusthubAdminVendorUserDetails === 'loading'
  ) {
    return <LoadingSpinner />;
  }

  const sectionProps = {
    sections,
    settings,
    isEditable: true,
    showDocumentDownloadIcon: false,
  };

  const esgSectionProps = {
    ...sectionProps,
    ...{
      identifier: 'environmental_social_and_governance',
    },
  };

  const complianceSectionProps = {
    ...sectionProps,
    ...{
      identifier: 'compliance',
    },
  };

  const controlsSectionProps = {
    ...sectionProps,
    ...{
      identifier: 'controls',
    },
  };

  return (
    <div className="trusthubAdminPage">
      <>
        <CompanyDetailsSection isEditable settings={settings} />
        <PoliciesSection {...esgSectionProps} />
        <PoliciesSection {...complianceSectionProps} />
        <CertificationsSection {...sectionProps} />
        <InformationSecuritySection {...sectionProps} />
        <ControlsSection {...controlsSectionProps} />
      </>

      {showSettingsDrawer && (
        <TrustHubSettingsDrawer
          settings={settings}
          onClose={() => dispatch(setShowSettingsDrawer(false))}
        />
      )}
    </div>
  );
};
