import React, { useEffect } from 'react';
import { CompanyDetailsSection } from '../../CompanyDetailsSection/CompanyDetailsSection';
import { InformationSecuritySection } from '../../InformationSecuritySection/InformationSecuritySection';
import adoptechLogoDark from 'images/adoptech-logo-dark.svg';
import {
  fetchTrustHubSections,
  fetchTrustHubSettings,
  fetchTrustHubVendorDocumentFile,
  fetchTrustHubVendorUserDetails,
} from '../../store/trusthubThunks';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { TrustHubUserPageHeaderButtons } from '../TrustHubUserPageHeaderButtons/TrustHubUserPageHeaderButtons';
import './TrustHubUserPage.scss';
import {
  TrusthubDocumentModel,
  TrusthubSettingsModel,
} from '../../../../swagger/trusthub';
import {
  setShowRequestDocumentModal,
  showTrustHubAuthModal,
} from '../../store/trusthubSlice';
import { TrustHubRequestDocumentModal } from '../TrustHubRequestDocumentModal/TrustHubRequestDocumentModal';
import { selectCurrentUser } from '../../store/trusthubSelectors';
import { portalRoutes } from '../../../../components/Routes/Routes';
import { ColorHeader } from '../../ColorHeader/ColorHeader';
import { CertificationsSection } from '../../CertificationsSection/CertificationsSection';
import { PoliciesSection } from '../../PoliciesSection/PoliciesSection';
import { ControlsSection } from '../../ControlsSection/ControlsSection';

export const currentTrustHubDomain = () => {
  return window.location.pathname.split('#')[0].split('/')[1];
};

export const useTrusthubDocumentDownload = (onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const handleDownload = (doc: TrusthubDocumentModel) => {
    dispatch(
      fetchTrustHubVendorDocumentFile(
        {
          documentId: doc.id,
        },
        (url: string) => {
          const tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.target = '_blank';
          onSuccess && onSuccess();
          tempLink.click();
        }
      )
    );
  };
  return handleDownload;
};

export const useDisplayTrusthubSettingsApiError = () => {
  const { fetchingSettingsStatusCode } = useSelector(
    (state: ApplicationState) => state.trustHub
  );

  const TrustHubApiSettingsErrors = {
    403: 'TrustHub is disabled. Please contact to the admin to enable TrustHub',
    404: 'Trusthub domain not found. Please contact to the support',
  };

  const hasApiError = [403, 404].includes(fetchingSettingsStatusCode);
  const apiError =
    TrustHubApiSettingsErrors[
      fetchingSettingsStatusCode as keyof typeof TrustHubApiSettingsErrors
    ];

  return { hasApiError, apiError };
};
export const TrustHubUserPage: React.FC = () => {
  const baseCss = 'trusthubUserPage';
  const {
    settings,
    sections,
    fetchingSectionsStatus,
    fetchingSettingsStatus,
    fetchTrustHubUserDetailsRequestStatus,
    showRequestDocumentModal,
  } = useSelector((state: ApplicationState) => state.trustHub);
  const dispatch = useDispatch();
  const domain = currentTrustHubDomain();
  useEffect(() => {
    dispatch(
      fetchTrustHubSections(
        {
          vendorIdOrDomain: domain,
        },
        () => {}
      )
    );
  }, [domain]);

  useEffect(() => {
    dispatch(
      fetchTrustHubSettings(
        {
          vendorIdOrDomain: domain,
        },
        (settingsModel: TrusthubSettingsModel) => {
          dispatch(fetchTrustHubVendorUserDetails(settingsModel.domain));
        }
      )
    );
  }, [domain]);

  const isLoggedIn = !!useSelector(selectCurrentUser);
  const handleDownload = useTrusthubDocumentDownload();

  const { apiError, hasApiError } = useDisplayTrusthubSettingsApiError();
  const setShowRequestDocModal = (showDocModal: boolean) => {
    dispatch(setShowRequestDocumentModal(showDocModal));
  };

  if (
    fetchingSectionsStatus === 'loading' ||
    fetchingSettingsStatus === 'loading' ||
    fetchTrustHubUserDetailsRequestStatus === 'loading'
  ) {
    return <LoadingSpinner />;
  }

  const onDocumentClick = (document: TrusthubDocumentModel) => {
    if (document.restricted) {
      if (isLoggedIn) {
        setShowRequestDocModal(true);
      } else {
        dispatch(showTrustHubAuthModal('required'));
      }
    } else {
      handleDownload(document);
    }
  };

  const sectionProps = {
    isEditable: false,
    showDocumentDownloadIcon: true,
    sections,
    settings,
    onDocumentClick,
  };

  const esgSectionProps = {
    ...sectionProps,
    ...{
      identifier: 'environmental_social_and_governance',
      title: 'Environmental, Social and Governance',
    },
  };

  const complianceSectionProps = {
    ...sectionProps,
    ...{
      identifier: 'compliance',
      title: 'Compliance',
    },
  };

  const controlsSectionProps = {
    ...sectionProps,
    ...{
      identifier: 'controls',
    },
  };

  return (
    <div className={baseCss}>
      <ColorHeader
        showBackButton={false}
        buttons={<TrustHubUserPageHeaderButtons />}
      />
      <div className={baseCss + '--content'}>
        {hasApiError ? (
          <div className="mt-4">{apiError}</div>
        ) : (
          <>
            <CompanyDetailsSection isEditable={false} settings={settings} />
            <PoliciesSection {...esgSectionProps} />
            <PoliciesSection {...complianceSectionProps} />
            <CertificationsSection {...sectionProps} />
            <InformationSecuritySection {...sectionProps} />
            <ControlsSection {...controlsSectionProps} />

            {showRequestDocumentModal && (
              <TrustHubRequestDocumentModal
                onClose={() => setShowRequestDocModal(false)}
              />
            )}
          </>
        )}
        <div className={baseCss + '--contentFooter'}>
          <div onClick={() => (window.location.href = portalRoutes.home)}>
            Powered by <img src={adoptechLogoDark} />
          </div>
        </div>
      </div>
    </div>
  );
};
